ohjaa_body {
  background: url(../images/ohjaa_app_bg.jpg) #FA575A center center no-repeat scroll !important;
  background-size: cover !important;
}

ohjaa_::placeholder {
  color: #FFEEEE !important;
}

.ohjaa_logo {
  background: url(../images/Ohjaa-powered_by_Tulka.png) center center no-repeat !important;
  background-size: contain !important;
}

.ohjaa_action {
  background-color: #213875 !important;
}

.ohjaa_action:active {
  background-color: #314885 !important;
}

.ohjaa_action:hover, .ohjaa_action:focus {
  background-color: #314885 !important;
}

#ohjaa_generalActions .fas {
  color: #FFFFFF !important;
  border-color: #FFFFFF !important;
  opacity: 0.5 !important;
}

ohjaa_textarea {
  color: #FFEEEE;
  background-color: rgba(255, 228, 228, 0.4) !important;
}

#ohjaa_customerOwnNotes {
  display: none !important;
}

#ohjaa_waitingDuration {
  color: #ffffff !important;
  opacity: 0.7 !important;
}

.slider {
  -webkit-appearance: none !important;
  width: 100% !important;
  height: 10px !important;
  border-radius: 5px !important;   
  background: rgba(255, 255, 255, 0.5) !important;
  outline: none !important;
  -webkit-transition: .2s !important;
  transition: opacity .2s !important;
  padding: 0 !important;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important; 
  background: #ffffff !important;
  cursor: pointer !important;
}

.slider::-moz-range-thumb {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  background: #ffffff !important;
  cursor: pointer !important;
}

.steps {
  display: inline-block;
  white-space: nowrap;
  overflow-x: hidden;
  margin-left: 7px;
  width: 300px;
}

.step {
  display: inline-block;
  width: 28px;
  text-align: left;
  color: #ffffff;
}

.step:last-child {
  width: auto;
}

#ohjaa_customerTimeLeft {
  opacity: 1.0 !important;
}

.ohjaa_menuButton {
  background-color: #FA575A !important;
  color: #ffffff !important;
}

.ohjaa_cancelButton {
  background-color: #213875 !important;
  color: #ffffff !important;
}

.ohjaa_menuButton:active {
  background-color: #FF5A5F !important;
}

.ohjaa_cancelButton:active {
  background-color: #314885 !important;
}

.ohjaa_menuText p {
  color: #213875 !important;
}

.ohjaa_systemAlert {
  color: #213875;
}

.ohjaa_sc-launcher {
  z-index: 100;
}

.ohjaa_sc-open-icon {
  z-index: 100;
}

.ohjaa_sc-closed-icon {
  z-index: 100;
}

.ohjaa_sc-chat-window {
  z-index: 100;
}

.ohjaa_sc-header {
  background: #FA575A !important;
}

.ohjaa_sc-launcher {
  background: #213875 !important;
}

.sc-message--content.received 
.ohjaa_sc-message--text {
  background: #213875 !important;
  color: white !important;
}

.sc-message--content.sent 
.ohjaa_sc-message--text {
  background: #FA575A !important;
  color: white !important;
}

.ohjaa_sc-header--close-button:hover {
  background: #FA575A !important; 
}

.ohjaa_sc-header--team-name:hover {
  background: #FA575A !important; 
}

.ohjaa_sc-new-messages-count {
  background: #FA575A !important; 
}