// @import url("https://fonts.googleapis.com/css?family=Montserrat:300,500,600,700");

* {
  box-sizing: border-box;
  outline: none;
}

::placeholder {
  color: #8080A8;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  margin: auto;
  font: normal 14px/18px 'Montserrat', helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.swal2-shown.swal2-height-auto {
  height: 100% !important;
}

#main {
  height: 100%;
  overflow: auto;
}

body:-webkit-full-screen {
  width: 100%;
  height: 100%;
}

:focus {
  outline: none;
}

h1, h2, h3, .noActionCustomer, .noActionInterpreter, label, #customerTimeLeft {
	font: normal 24px/30px 'Montserrat', serif;
	font-weight: 600;
	letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ffffff;
}

h2 {
	font-size: 20px;
	line-height: 24px;
}

h3, .noActionCustomer, .noActionInterpreter, label {
	font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

a, p, div, input, select, button {
  font: normal 14px/18px 'Montserrat', helvetica, sans-serif;
  text-decoration: none;
  letter-spacing: 1px;
  color: #151529;
}

input, select, textarea {
  font: normal 14px/18px 'Montserrat', helvetica, sans-serif;
  letter-spacing: 1px;
  color: #151529;
  background-color: rgba(228, 228, 255, 0.5);
  border: 0px none;
  border-radius: 4px;
}

#devices select {
  width: 300px;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

#devices {
  position: relative;
  width: 300px;
  z-index: 5;

  margin: auto;
  margin-top: 10px;
  display: block;
  margin-bottom: 10px;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.logo,
.logo:active,
.logo:hover {
  position: relative;
  margin: auto;
  margin-top: 40px;
  padding-top: 20px;
	font-size: 0px;
	display: block;
	width: 180px;
  height: 120px;
  background: url(../images/tulka.png) center bottom no-repeat content-box;
  background-size: contain;
}

#version {
  margin: auto;
  margin: 10px auto;
  color: rgba(255, 255, 255, 0.2);
  display: block;
  width: 180px;
  text-align: center;
}

.action.disabled, .action:disabled {
  background-color: #888890;
  color: #B4B4B4;
}

.action.disabled:hover, .action:disabled:hover {
  background-color: #888890 !important;
  cursor: default;
}

.action.login {
  background-color: #EA7E8F !important;
}

.action.login.disabled, .action.login:disabled {
  background-color: #888890 !important;
}

.dialog {
  position: relative;
  width: 300px;
  z-index: 5;

  margin: auto;
  margin-top: 10px;
  display: block;
}

.dialog h2 {
	text-align: center;
	/*text-transform: uppercase;*/
  margin-top: 0.75em;
}

.dialog h3 {
  text-align: center;
}

.action {
  display: block;
  position: relative;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
	background-color: #8DD1D4;
  border-width: 0px;
  border-radius: 4px;
	min-width: 200px;
	margin: 20px auto;
	text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  padding: 15px;
}

.action:hover,
.action:focus {
  background-color: #BFF1F4;
}

.action:active {
  background-color: #5080B0;
  border-style: inset;
}

.acceptRejectContainer {
  width: 250px;
  height: 120px;
  margin: auto;
}

.acceptButton, .rejectButton {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-top: 20px;
  cursor: pointer;
}

.acceptButton {
  background-image: url(../images/accept_call.png);
  background-repeat: no-repeat;
}

.rejectButton {
  background: rgba(0, 0, 0, 0) url(../images/reject_call.png) no-repeat right top;
  margin-right: 90px;
}

select {
	display: block;
	font-size: 16px;
	width: 100%;
	height: 48px;
	margin: 20px auto;
}

.dialog .secondaryAction {
  font-size: 12px;
  color: #A1BBE1;
  background-color: transparent;
  border-width: 0px;
	margin: 20px auto;
  display: block;
  cursor: pointer;
}

#reviewDialogCustomer input,
#reviewDialogCustomer textarea,
#reviewDialogInterpreter input,
#reviewDialogInterpreter textarea {
  background-color: #46446F;
  border: 1px solid #ffffff;
  height: 40px;
  font-size: 16px;
}

.checkbox {
  position: relative;
  display: block;
  height: 48px;
}

label {
  display: inline-block;
  color: #ffffff;
  height: 48px;
  line-height: 48px;

  position: absolute;
  top: 50%;
  margin-top: -24px;
}

.dialog a {
  display: block;
  text-align: center;
  margin: 0px auto;
}

#chromeDialog img {
  width: 64px;
  height: 64px;
}

.stars {
  display: flex;
  justify-content: center;
  align-content: center;
  list-style-type: none;
  margin: 0px;
  margin-bottom: 40px;
  padding: 0px;
  height: 32px;
}

.stars .star {
  color: #4178CC;
  margin-right: 35px;
  background: url(../images/star_gray.png) rgba(0, 0, 0, 0) no-repeat;
  background-size: contain;
  width: 32px;
}

.stars .star:last-child {
  margin-right: 0px;
}

.stars .star.selected,
.stars .star.hovered,
.stars .star.hovered.selected {
  background: url(../images/star_colored.png) rgba(0, 0, 0, 0) no-repeat;
  background-size: contain;
}

.compactStar {
  width: 22px !important;
  height: 22px !important;
  margin-right: 0px !important;
}

.starRow {
  margin-bottom: 8px;
}

.nameCol, .starCol, .dateCol {
  vertical-align: top;
}

.starCol {
  width: 150px;
}

.nameCol {
  white-space: nowrap;
}

.ratings {
  overflow-y: scroll;
  overflow-x: scroll;
  height: calc(100vh - 220px);
  width: 100vw;
}

.ratingsTable {
  margin-left: auto;
  margin-right: auto;
}

.ratings tr:nth-child(odd) td * {
  font-family: 'Montserrat', serif;
  font-size: 12px;
  color: white;
}

.ratings tr:nth-child(even) td * {
  font-family: 'Montserrat', serif;
  font-size: 12px;
  color: #A1BBE1;
}

.videos {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #46446F;
}

#publisher {
	background-color: #46446F;
	position: absolute;
	z-index: 2;
	top: 20px;
	right: 20px;
	width: 16vw;
  height: 9vw;
  min-height: 52px;
  border: 2px solid #ffffff;
  opacity: 1.0;
  transition: opacity 600ms;
}

#subscriber {
	position: absolute;
	top: 0;
  bottom: 0;
}

.subscriberLandscape {
  left: 0;
  right: 0;
}

.subscriberPortrait {
  left: calc(50% - (50vh * 9/16));
  right: calc(50% - (50vh * 9/16));
}

#generalActions {
  position: absolute;
  left: 10px;
  top: 15px;
  width: 50px;
  height: 50px;
  font-size: 24px !important;
  font-weight: normal !important;
  cursor: pointer;
  color: #ffffff;
  float: left;
}

#generalActions:hover,
#generalActions:focus {
  background-color: rgba(0, 0, 0, 0);
}

#videoDevices, #audioDevices {
  position: relative;
  width: 300px;
  height: 60px;
  z-index: 6;
  margin: 0 auto;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}

#videoDevices select, #audioDevices select {
  width: 200px;
  margin-right: 50px;
}

#callOverlay {
  z-index: 6;
}

#callInfo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100%;
  height: 60px;
  z-index: 6;
}

#callDuration {
  position: absolute;
  width: 100%;
  height: 30px;
  z-index: 6;
  bottom: 105px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;

  font-size: 18px;
  line-height: 22px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: 600;
}

#badConnection {
  position: absolute;
  width: 100%;
  height: 30px;
  z-index: 6;
  bottom: 165px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;

  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
  font-weight: 600;

  color: #ff0000;
}

#waitingDuration {
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #888888 !important;
}

#callerName {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #ffffff;
}

#callLanguage {
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 10px;
}

#timeLeftTitle {
  display: inline;
}

#timeLeft, #timeLeftTitle {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #cccccc;
}

.runningOutOfTime {
  color: #E03333 !important;
  font-weight: 600 !important;
}

.superLowTimeLeft {
  font-size: 26px !important;
  line-height: 30px;
  /* center */
}

#customerFeedback, #interpreterFeedback {
  display: block;
	width: 300px;
	height: 96px !important;
  margin: 10px 0;
  margin-top: 0;
  padding: 10px;
}

#customerInvoiceRef {
  display: block;
  width: 300px;
  height: 96px !important;
  margin: 10px 0;
  margin-top: 0;
  padding: 10px;
}

.giveRatingCustomer {
  margin-top: 40px !important;
}

.menu,
.stats,
.refresh {
  position: absolute;
  right: -10px;
  top: 15px;
  width: 50px;
  height: 50px;
  font-size: 24px !important;
  font-weight: normal !important;
  cursor: pointer;
  color: #ffffff;
  float: right;
}

.stats {
  left: 10px;
  float: left;
}

.refresh {
  left: 70px;
  float: left;
}

.stats:hover,
.stats:focus,
.refresh:hover,
.refresh:focus,
.menu:hover,
.menu:focus {
  background-color: rgba(0, 0, 0, 0);
}

.stats:active,
.menu:active {
  background-color: rgba(0, 0, 0, 0);
  color: #56547F;
  transform: scale(1.0);
}

#videoDevices .fa, #audioDevices .fas {
  display: inline-block;
  width: 48px;
  height: 48px;
  font-size: 24px;
  font-weight: normal;
  color: rgba(228, 228, 255, 0.5);
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  padding: 10px;
  border-radius: 4px;
}

.endSession, .toggleCamera, .toggleAudio {
  position: relative;
  transition: transform 1000ms;
}

.visible {
  display: block !important;
  visibility: visible !important;
}

.toggleCamera.disabled {
  background: url(../images/video_off.png) rgba(0, 0, 0, 0) no-repeat;
  background-size: cover !important;
}

.toggleAudio.disabled {
  background: url(../images/mic_off.png) rgba(0, 0, 0, 0) no-repeat;
  background-size: cover !important;
}

.noActionCustomer, .noActionInterpreter {
  margin-bottom: 20px !important;
}

.spinner {
  position: absolute;
  top: 50%;
  margin-top: -13px;
  left: 50%;
  margin-left: -13px;
}

#timeLeftContainer {
  position: absolute;
  bottom: 50px;
  width: 100%;
  height: auto;
  z-index: 6;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}

#customerTimeLeft {
  font-weight: 300;
  letter-spacing: 0.5px;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.6;
  width: 300px;
  text-align: center;
  white-space: pre-wrap;
  margin: 0;
}

.eula {
  position: absolute;
  bottom: 20px;
  width: 100%;
  height: auto;
  z-index: 6;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: 12px;
  line-height: 12px;
  color: #26243F;
}

.hidden {
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}

.menuButton {
  display: block;
  border: none;
  background-color: #8DD1D4;
  color: #ffffff;
  height: 50px;
  width: 250px;
  margin-bottom: 10px;
  font-weight: 600;
  cursor: pointer;
}

.cancelButton {
  background-color: #BFF1F4;
  color: #688184;
}

.menuButton:active {
  background-color: #BFF1F4;
}

.cancelButton:active {
  background-color: #8DD1D4;
}

.menuText {
  text-align: center;
}

.menuText p {
  font-weight: 600;
  color: #8DD1D4;
  text-align: center;
}

.ReactModalPortal > div {
  opacity: 0;
  z-index: 9999999;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.5);
  &--after-open {
      opacity: 1;
  }
  &--before-close {
      opacity: 0;
  }
}

.disabledDuration {
  color: #8DD1D4;
}

.systemAlert {
  color: #EA7E8F;
}

.sc-launcher, .sc-open-icon, .sc-closed-icon {
  z-index: 100;
}

.sc-chat-window {
  z-index: 100;
}

.sc-header {
  background: #56547F !important;
}

.sc-launcher {
  background: #8DD1D4 !important;
}

.sc-message--content.received
.sc-message--text {
  background: #A1BBE1 !important;
  color: white !important;
}

.sc-message--content.sent
.sc-message--text {
  background: #4178CC !important;
  color: white !important;
}

.delete-message {
  display: none !important;
  visibility: hidden !important;
  width: 0 !important;
  height: 0 !important;
}

.sc-header--close-button:hover {
  background: #56547F !important;
}

.sc-header--team-name:hover {
  background: #56547F !important;
}

.sc-new-messages-count {
  background: #EA7E8F !important;
}

.sc-chat-window {
  max-height: 500px !important;
}

@media (max-width: 450px) {
  .sc-chat-window {
    max-height: 500px !important;
  }

  .sc-launcher {
    bottom: 125px !important;
  }

  .sc-launcher .sc-open-icon,
  .sc-launcher .sc-closed-icon {
    bottom: 125px !important;
  }
}

.showRatings {
  position: static;
}

.statistics tr:nth-child(odd) td {
  padding-top: 20px;
}

.textCol,
.numCol {
  font-family: 'Montserrat', serif;
  font-size: 16px;
  line-height: 20x;
  text-align: left;
  color: #A1BBE1;
  width: 200px;
  font-weight: 400;
}

.userNamePhone,
.numCol {
  text-align: right;
  width: 100px;
  color: #A1BBE1;
}

.firstRow td.numCol,
.firstRow td.textCol
 {
  color: white;
  font-size: 16px;
  line-height: 22px;
}

#statisticsUsername,
#statisticsPhone {
  width: 300px;
  color: white;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

#statsDisclaimer {
  font-size: 14px;
  color: #A1BBE1;
  font-weight: 300;
  padding-top: 20px;
}

#statisticsUsername {
  margin-top: 30px;
}

.name-1-0-3 {
  overflow: visible !important;
  max-width: 100% !important;
}
